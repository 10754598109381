import { React, useEffect, useState } from 'react'
import Header from '../../../layouts/header'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getListStaff } from '../../../helpers/helperFunctions'
import { isEmpty } from 'lodash'
import Spinner from '../../../components/spinner'
import { Formik } from 'formik'
import { Modal } from 'react-bootstrap'
import FormControl from '../../../components/formControl'
import { API, Storage } from 'aws-amplify'
import { deleteResident, deleteSocietyUser, deleteUser, updateUser } from '../../../graphql/mutations'
import swal from 'sweetalert'
import { getUser, listUsers } from '../../../graphql/queries'
import { customGetUser } from '../../../customQueries/customQueries'


const StaffList = () => {
    const navigate = useNavigate()
    const [users, setUsers] = useState()
    const [spinner, showSpinner] = useState(false)
    const [modal, showModal] = useState(false)
    const [nextArr, setNextArr] = useState([])
    const [searchFilterUsers, setSearchFilterUsers] = useState()
    const [filterUsers, setFilterUsers] = useState()
    const [formData, setFormData] = useState({
        name: '',
        image: '',
        id: ''
    })

    useEffect(() => {
        fetchStaffList(null, true)
    }, [])

    const fetchStaffList = async (token, preve) => {
        showSpinner(true)
        try {
            getListStaff(token).then((res) => {
                if (!isEmpty(res)) {
                    if (res?.data?.listUsers?.nextToken && preve) {
                        let newUsers = res?.data?.listUsers?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setUsers(res?.data?.listUsers?.items);
                }
                showSpinner(false)
            })
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            fetchStaffList(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            fetchStaffList(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            fetchStaffList(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const createStaff = () => {
        navigate('/create-staff')
    }

    const handleEdit = (societyUser) => {
        setFormData({
            name: societyUser.name,
            id: societyUser.id,
            image: societyUser.photo
        })
        showModal(true)
    }

    const handleRemove = (form, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete User?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                API.graphql({
                    query: deleteUser,
                    variables: {
                        input: {
                            id: form.id
                        }
                    }
                }).then(() => {
                    const temp = [...users]
                    temp.splice(index, 1)
                    setUsers(temp)
                    toast.success('User deleted successfully')
                }).catch((err) => toast.error(err.message))
            }
        }
        ).then((res) => {
            API.graphql({
                query: customGetUser,
                variables: {
                    id: form.id
                }
            }).then((res) => {
                if (!isEmpty(res.data.getUser.residents.items)) {
                    for (let data of res.data.getUser.residents.items) {
                        API.graphql({
                            query: deleteResident,
                            variables: {
                                input: {
                                    id: data.data.getUser.residents.items.id
                                }
                            }
                        }).then((res) => {
                            console.log('removed from resident', res.data)
                        })
                    }
                } else {
                    console.log('no resident')
                }
                if (!isEmpty(res.data.getUser.societies.items)) {
                    for (let data of res.data.getUser.societies.items) {
                        API.graphql({
                            query: deleteSocietyUser,
                            variables: {
                                input: {
                                    id: data.data.getUser.societies.items.id
                                }
                            }
                        }).then((res) => {
                            console.log('removed from resident', res.data)
                            showSpinner(false)
                        })
                    }
                } else {
                    console.log('no resident')
                }
            })
        })
    }

    const handleEditSocietyUser = async (form) => {
        try {
            let updatedUser = await API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: form.id,
                        photo: form.image,
                        name: form.name
                    }
                }
            })
            setUsers((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index] = updatedUser.data.updateUser;
                return [...prevState];
            });
            showModal(false)
        } catch (err) {
            console.log(err)
        }
    }

    const handleSearchChange = (e) => {
        setSearchFilterUsers(e.target.value.trim());
        if (e.target.value) {
            API.graphql({
                query: listUsers,
                variables: {
                    filter: { name: { contains: e?.target?.value[0]?.toUpperCase() + e.target.value.slice(1) || e?.target?.value[0]?.toLowerCase() + e.target.value.slice(1) } }
                }
            }).then((res) => {
                setFilterUsers(res?.data?.listUsers?.items)
            })
        }
        else if (isEmpty(e.target.value)) {
            setFilterUsers([]);
            if (nextArr.length <= 2) {
                fetchStaffList(null, false)
            } else {
                fetchStaffList(nextArr[nextArr.length - 2], true)
            }
        }
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]

        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }

    return (
        <>
            <Header title={'Staff List'} buttonTitle={'Create'} onclick={createStaff} />
            <div className="tab-content">
                <div className="tab-pane fade active show mb-3" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">
                    <div className="card">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <form>
                                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                            <input className="form-control list-search" type="text" value={searchFilterUsers} placeholder="Search" onChange={handleSearchChange} />
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th className='text-center'>image</th>
                                        <th className='text-center'>Name</th>
                                        <th className='text-center'>Phone</th>
                                        <th className='text-center'>Role</th>
                                        <th className='text-center'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base">
                                    {isEmpty(filterUsers) && isEmpty(searchFilterUsers) ? users?.map((item, index) => (
                                        <tr key={index}>
                                            <td className='text-center'>
                                                <div className="avatar avatar-xs align-middle me-2">
                                                    <img className="avatar-img rounded-circle" src={item?.photo} alt="..." />
                                                </div>
                                            </td>
                                            <td className='text-center'>{item.name}</td>
                                            <td className='text-center'>{item.phone}</td>
                                            <td className='text-center'>{item.role}</td>
                                            <td className='text-center'>
                                                <div className='dropdown'>
                                                    <a href='#' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                        <i className='fe fe-more-vertical'></i>
                                                    </a>
                                                    <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                        <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleEdit(item) }}>
                                                            Edit
                                                        </div>
                                                        <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleRemove(item, index) }}>
                                                            Delete user
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                        : filterUsers?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='text-center'>
                                                    <div className="avatar avatar-xs align-middle me-2">
                                                        <img className="avatar-img rounded-circle" src={item?.photo} alt="..." />
                                                    </div>
                                                </td>
                                                <td className='text-center'>{item.name}</td>
                                                <td className='text-center'>{item.phone}</td>
                                                <td className='text-center'>{item.role}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <a href='#' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </a>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleRemove(item, index) }}>
                                                                Delete user
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            <hr className='m-0' />
                            <div className='card-footer d-flex justify-content-between'>
                                <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                    <li className='page-item' >
                                        <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                            <i className='fe fe-arrow-left me-1'></i> Prev
                                        </Link>
                                    </li>
                                </ul>
                                <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                    <li className='page-item'>
                                        <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                            Next <i className='fe fe-arrow-right ms-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={modal} onHide={() => showModal(false)}>
                    <Modal.Body>
                        <h3>Edit Society Staff</h3>
                        <hr />
                        <Formik initialValues={formData} onSubmit={handleEditSocietyUser} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => {

                                return (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <FormControl type='text' name='name' title='Society Name'
                                            value={values?.name} error={errors.name} onChange={handleChange} />

                                        <div className='form-group mt-3'>
                                            {values?.image && <img src={values?.image} width={200} alt='' className='preview' />}
                                            <div>
                                                <label>Image</label>
                                                <input name='image' type='file' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className='form-control' />
                                                {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                            </div>
                                        </div>

                                        <Spinner show={spinner}>
                                            <button className='btn btn-primary' type='submit'>Update</button>
                                            <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                        </Spinner>
                                    </form>
                                )
                            }
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    )
}

export default StaffList
