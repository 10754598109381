/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSocietyUser = /* GraphQL */ `
  query GetSocietyUser($id: ID!) {
    getSocietyUser(id: $id) {
      id
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      role
      createdAt
      updatedAt
    }
  }
`;
export const listSocietyUsers = /* GraphQL */ `
  query ListSocietyUsers(
    $filter: ModelSocietyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocietyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const societyUsersByUserId = /* GraphQL */ `
  query SocietyUsersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSocietyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    societyUsersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const societyUsersBySocietyId = /* GraphQL */ `
  query SocietyUsersBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSocietyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    societyUsersBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSocietyUsers = /* GraphQL */ `
  query SearchSocietyUsers(
    $filter: SearchableSocietyUserFilterInput
    $sort: [SearchableSocietyUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSocietyUserAggregationInput]
  ) {
    searchSocietyUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getAmenity = /* GraphQL */ `
  query GetAmenity($id: ID!) {
    getAmenity(id: $id) {
      id
      societyId
      buildingId
      name
      description
      slots
      cost
      free
      media
      active
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAmenities = /* GraphQL */ `
  query ListAmenities(
    $filter: ModelAmenityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmenities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const amenitiesBySocietyId = /* GraphQL */ `
  query AmenitiesBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAmenityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    amenitiesBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const amenitiesByBuildingId = /* GraphQL */ `
  query AmenitiesByBuildingId(
    $buildingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAmenityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    amenitiesByBuildingId(
      buildingId: $buildingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAmenityBooking = /* GraphQL */ `
  query GetAmenityBooking($id: ID!) {
    getAmenityBooking(id: $id) {
      id
      amenityId
      amenity {
        id
        societyId
        buildingId
        name
        description
        slots
        cost
        free
        media
        active
        createdAt
        updatedAt
      }
      residentId
      resident {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      societyId
      society {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      slotStartDateTime
      slotEndDateTime
      status
      amount
      invoiceId
      invoiceStatus
      createdByUser {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      invoiceDate
      dueDate
      statusHistory
      createdAt
      updatedAt
      amenityBookingCreatedByUserId
    }
  }
`;
export const listAmenityBookings = /* GraphQL */ `
  query ListAmenityBookings(
    $filter: ModelAmenityBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAmenityBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amenityId
        residentId
        societyId
        unitId
        slotStartDateTime
        slotEndDateTime
        status
        amount
        invoiceId
        invoiceStatus
        invoiceDate
        dueDate
        statusHistory
        createdAt
        updatedAt
        amenityBookingCreatedByUserId
      }
      nextToken
    }
  }
`;
export const amenityBookingsByAmenityId = /* GraphQL */ `
  query AmenityBookingsByAmenityId(
    $amenityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAmenityBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    amenityBookingsByAmenityId(
      amenityId: $amenityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amenityId
        residentId
        societyId
        unitId
        slotStartDateTime
        slotEndDateTime
        status
        amount
        invoiceId
        invoiceStatus
        invoiceDate
        dueDate
        statusHistory
        createdAt
        updatedAt
        amenityBookingCreatedByUserId
      }
      nextToken
    }
  }
`;
export const amenityBookingsByResidentId = /* GraphQL */ `
  query AmenityBookingsByResidentId(
    $residentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAmenityBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    amenityBookingsByResidentId(
      residentId: $residentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amenityId
        residentId
        societyId
        unitId
        slotStartDateTime
        slotEndDateTime
        status
        amount
        invoiceId
        invoiceStatus
        invoiceDate
        dueDate
        statusHistory
        createdAt
        updatedAt
        amenityBookingCreatedByUserId
      }
      nextToken
    }
  }
`;
export const amenityBookingsBySocietyId = /* GraphQL */ `
  query AmenityBookingsBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAmenityBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    amenityBookingsBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amenityId
        residentId
        societyId
        unitId
        slotStartDateTime
        slotEndDateTime
        status
        amount
        invoiceId
        invoiceStatus
        invoiceDate
        dueDate
        statusHistory
        createdAt
        updatedAt
        amenityBookingCreatedByUserId
      }
      nextToken
    }
  }
`;
export const amenityBookingsByUnitId = /* GraphQL */ `
  query AmenityBookingsByUnitId(
    $unitId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAmenityBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    amenityBookingsByUnitId(
      unitId: $unitId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amenityId
        residentId
        societyId
        unitId
        slotStartDateTime
        slotEndDateTime
        status
        amount
        invoiceId
        invoiceStatus
        invoiceDate
        dueDate
        statusHistory
        createdAt
        updatedAt
        amenityBookingCreatedByUserId
      }
      nextToken
    }
  }
`;
export const getVisitor = /* GraphQL */ `
  query GetVisitor($id: ID!) {
    getVisitor(id: $id) {
      id
      societyId
      unitId
      unit {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      visitor {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      resident {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      validFrom
      validTo
      type
      preApproved
      entries {
        nextToken
      }
      createdAt
      updatedAt
      visitorVisitorId
      visitorResidentId
    }
  }
`;
export const listVisitors = /* GraphQL */ `
  query ListVisitors(
    $filter: ModelVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisitors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      nextToken
    }
  }
`;
export const visitorsBySocietyId = /* GraphQL */ `
  query VisitorsBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visitorsBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      nextToken
    }
  }
`;
export const visitorsByUnitId = /* GraphQL */ `
  query VisitorsByUnitId(
    $unitId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVisitorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visitorsByUnitId(
      unitId: $unitId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      nextToken
    }
  }
`;
export const searchVisitors = /* GraphQL */ `
  query SearchVisitors(
    $filter: SearchableVisitorFilterInput
    $sort: [SearchableVisitorSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableVisitorAggregationInput]
  ) {
    searchVisitors(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getVisitorEntry = /* GraphQL */ `
  query GetVisitorEntry($id: ID!) {
    getVisitorEntry(id: $id) {
      id
      visitorId
      visitor {
        id
        societyId
        unitId
        validFrom
        validTo
        type
        preApproved
        createdAt
        updatedAt
        visitorVisitorId
        visitorResidentId
      }
      societyId
      unitId
      security {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      entry
      exit
      media
      notes
      token
      createdAt
      updatedAt
      visitorEntrySecurityId
    }
  }
`;
export const listVisitorEntries = /* GraphQL */ `
  query ListVisitorEntries(
    $filter: ModelVisitorEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVisitorEntries(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        visitorId
        societyId
        unitId
        type
        approved
        entry
        exit
        media
        notes
        token
        createdAt
        updatedAt
        visitorEntrySecurityId
      }
      nextToken
    }
  }
`;
export const visitorEntriesByVisitorId = /* GraphQL */ `
  query VisitorEntriesByVisitorId(
    $visitorId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVisitorEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visitorEntriesByVisitorId(
      visitorId: $visitorId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        visitorId
        societyId
        unitId
        type
        approved
        entry
        exit
        media
        notes
        token
        createdAt
        updatedAt
        visitorEntrySecurityId
      }
      nextToken
    }
  }
`;
export const visitorEntriesBySocietyId = /* GraphQL */ `
  query VisitorEntriesBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVisitorEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visitorEntriesBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        visitorId
        societyId
        unitId
        type
        approved
        entry
        exit
        media
        notes
        token
        createdAt
        updatedAt
        visitorEntrySecurityId
      }
      nextToken
    }
  }
`;
export const visitorEntriesByUnitId = /* GraphQL */ `
  query VisitorEntriesByUnitId(
    $unitId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVisitorEntryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    visitorEntriesByUnitId(
      unitId: $unitId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        visitorId
        societyId
        unitId
        type
        approved
        entry
        exit
        media
        notes
        token
        createdAt
        updatedAt
        visitorEntrySecurityId
      }
      nextToken
    }
  }
`;
export const getVechicle = /* GraphQL */ `
  query GetVechicle($id: ID!) {
    getVechicle(id: $id) {
      id
      societyId
      unitId
      name
      number
      type
      createdAt
      updatedAt
    }
  }
`;
export const listVechicles = /* GraphQL */ `
  query ListVechicles(
    $filter: ModelVechicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVechicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        unitId
        name
        number
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vechiclesBySocietyId = /* GraphQL */ `
  query VechiclesBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVechicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vechiclesBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        unitId
        name
        number
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const vechiclesByUnitId = /* GraphQL */ `
  query VechiclesByUnitId(
    $unitId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelVechicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    vechiclesByUnitId(
      unitId: $unitId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        unitId
        name
        number
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResident = /* GraphQL */ `
  query GetResident($id: ID!) {
    getResident(id: $id) {
      id
      societyId
      society {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      unitId
      unit {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      userId
      user {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      type
      approved
      complaints {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResidents = /* GraphQL */ `
  query ListResidents(
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResidents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const residentsBySocietyId = /* GraphQL */ `
  query ResidentsBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    residentsBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const residentsByBuildingId = /* GraphQL */ `
  query ResidentsByBuildingId(
    $buildingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    residentsByBuildingId(
      buildingId: $buildingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const residentsByUnitId = /* GraphQL */ `
  query ResidentsByUnitId(
    $unitId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    residentsByUnitId(
      unitId: $unitId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const residentsByUserId = /* GraphQL */ `
  query ResidentsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResidentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    residentsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchResidents = /* GraphQL */ `
  query SearchResidents(
    $filter: SearchableResidentFilterInput
    $sort: [SearchableResidentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidentAggregationInput]
  ) {
    searchResidents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      societyId
      buildingId
      building {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      type
      name
      description
      size
      residents {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUnits = /* GraphQL */ `
  query ListUnits(
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const unitsBySocietyId = /* GraphQL */ `
  query UnitsBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const unitsByBuildingId = /* GraphQL */ `
  query UnitsByBuildingId(
    $buildingId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    unitsByBuildingId(
      buildingId: $buildingId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUnits = /* GraphQL */ `
  query SearchUnits(
    $filter: SearchableUnitFilterInput
    $sort: [SearchableUnitSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUnitAggregationInput]
  ) {
    searchUnits(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getBuilding = /* GraphQL */ `
  query GetBuilding($id: ID!) {
    getBuilding(id: $id) {
      id
      societyId
      name
      units {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $filter: ModelBuildingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const buildingsBySocietyId = /* GraphQL */ `
  query BuildingsBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    buildingsBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getState = /* GraphQL */ `
  query GetState($id: ID!) {
    getState(id: $id) {
      id
      name
      cities {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStates = /* GraphQL */ `
  query ListStates(
    $filter: ModelStateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($id: ID!) {
    getCity(id: $id) {
      id
      name
      societies {
        nextToken
      }
      stateId
      state {
        id
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        stateId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const citiesByStateId = /* GraphQL */ `
  query CitiesByStateId(
    $stateId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    citiesByStateId(
      stateId: $stateId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        stateId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSociety = /* GraphQL */ `
  query GetSociety($id: ID!) {
    getSociety(id: $id) {
      id
      organizationId
      type
      name
      cityId
      address
      photo
      buildings {
        nextToken
      }
      units {
        nextToken
      }
      vechicles {
        nextToken
      }
      visitors {
        nextToken
      }
      visitorEntries {
        nextToken
      }
      amenities {
        nextToken
      }
      residents {
        nextToken
      }
      users {
        nextToken
      }
      complaintTypes {
        nextToken
      }
      complaints {
        nextToken
      }
      notices {
        nextToken
      }
      bookings {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSocieties = /* GraphQL */ `
  query ListSocieties(
    $filter: ModelSocietyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocieties(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const societiesByCityId = /* GraphQL */ `
  query SocietiesByCityId(
    $cityId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSocietyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    societiesByCityId(
      cityId: $cityId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchSocieties = /* GraphQL */ `
  query SearchSocieties(
    $filter: SearchableSocietyFilterInput
    $sort: [SearchableSocietySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSocietyAggregationInput]
  ) {
    searchSocieties(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getComplaintType = /* GraphQL */ `
  query GetComplaintType($id: ID!) {
    getComplaintType(id: $id) {
      id
      societyId
      society {
        id
        organizationId
        type
        name
        cityId
        address
        photo
        createdAt
        updatedAt
      }
      name
      priorityType
      SOS
      complaints {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listComplaintTypes = /* GraphQL */ `
  query ListComplaintTypes(
    $filter: ModelComplaintTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComplaintTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const complaintTypesBySocietyId = /* GraphQL */ `
  query ComplaintTypesBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComplaintTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    complaintTypesBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComplaint = /* GraphQL */ `
  query GetComplaint($id: ID!) {
    getComplaint(id: $id) {
      id
      residentId
      resident {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      societyId
      complaintTypeId
      complainType {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      description
      assignedTo {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      comment
      media
      status
      feedback
      rating
      complaintHistory
      type
      createdAt
      updatedAt
      complaintAssignedToId
    }
  }
`;
export const listComplaints = /* GraphQL */ `
  query ListComplaints(
    $filter: ModelComplaintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComplaints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        residentId
        societyId
        complaintTypeId
        description
        comment
        media
        status
        feedback
        rating
        complaintHistory
        type
        createdAt
        updatedAt
        complaintAssignedToId
      }
      nextToken
    }
  }
`;
export const complaintsByResidentId = /* GraphQL */ `
  query ComplaintsByResidentId(
    $residentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComplaintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    complaintsByResidentId(
      residentId: $residentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        residentId
        societyId
        complaintTypeId
        description
        comment
        media
        status
        feedback
        rating
        complaintHistory
        type
        createdAt
        updatedAt
        complaintAssignedToId
      }
      nextToken
    }
  }
`;
export const complaintsBySocietyId = /* GraphQL */ `
  query ComplaintsBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComplaintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    complaintsBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        residentId
        societyId
        complaintTypeId
        description
        comment
        media
        status
        feedback
        rating
        complaintHistory
        type
        createdAt
        updatedAt
        complaintAssignedToId
      }
      nextToken
    }
  }
`;
export const complaintsByComplaintTypeId = /* GraphQL */ `
  query ComplaintsByComplaintTypeId(
    $complaintTypeId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelComplaintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    complaintsByComplaintTypeId(
      complaintTypeId: $complaintTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        residentId
        societyId
        complaintTypeId
        description
        comment
        media
        status
        feedback
        rating
        complaintHistory
        type
        createdAt
        updatedAt
        complaintAssignedToId
      }
      nextToken
    }
  }
`;
export const searchComplaints = /* GraphQL */ `
  query SearchComplaints(
    $filter: SearchableComplaintFilterInput
    $sort: [SearchableComplaintSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableComplaintAggregationInput]
  ) {
    searchComplaints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        residentId
        societyId
        complaintTypeId
        description
        comment
        media
        status
        feedback
        rating
        complaintHistory
        type
        createdAt
        updatedAt
        complaintAssignedToId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      photo
      birthday
      gender
      role
      residents {
        nextToken
      }
      societies {
        nextToken
      }
      devices {
        nextToken
      }
      notifications {
        nextToken
      }
      pin
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getNotice = /* GraphQL */ `
  query GetNotice($id: ID!) {
    getNotice(id: $id) {
      id
      title
      societyId
      description
      media
      createdBy {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      noticeCreatedById
    }
  }
`;
export const listNotices = /* GraphQL */ `
  query ListNotices(
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        societyId
        description
        media
        createdAt
        updatedAt
        noticeCreatedById
      }
      nextToken
    }
  }
`;
export const noticesBySocietyId = /* GraphQL */ `
  query NoticesBySocietyId(
    $societyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    noticesBySocietyId(
      societyId: $societyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        societyId
        description
        media
        createdAt
        updatedAt
        noticeCreatedById
      }
      nextToken
    }
  }
`;
export const getUserDevice = /* GraphQL */ `
  query GetUserDevice($id: ID!) {
    getUserDevice(id: $id) {
      id
      userId
      type
      name
      meta
      token
      arn
      createdAt
      updatedAt
    }
  }
`;
export const listUserDevices = /* GraphQL */ `
  query ListUserDevices(
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        name
        meta
        token
        arn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userDevicesByUserId = /* GraphQL */ `
  query UserDevicesByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userDevicesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        name
        meta
        token
        arn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      userId
      type
      typeId
      senderId
      sender {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
      }
      title
      message
      sent
      read
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        typeId
        senderId
        title
        message
        sent
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const notificationsByDate = /* GraphQL */ `
  query NotificationsByDate(
    $userId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationsByDate(
      userId: $userId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        type
        typeId
        senderId
        title
        message
        sent
        read
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
