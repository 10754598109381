import { API, Storage } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { custumGetAllSocietyData } from '../../../customQueries/customQueries'
import { useParams } from 'react-router-dom'
import Spinner from '../../../components/spinner'
import { Modal } from 'react-bootstrap'
import FormControl from '../../../components/formControl'
import { Formik } from 'formik'
import * as Yup from 'yup';
import { deleteSocietyUser, updateSocietyUser, updateUser } from '../../../graphql/mutations'
import swal from 'sweetalert'
import { toast } from 'react-toastify'


function Users() {
    const userRole = require('../../../data/userRole.json')
    const params = useParams()
    const [users, setUsers] = useState()
    const [spinner, showSpinner] = useState()
    const [modal, showModal] = useState()

    const [formData, setFormData] = useState({
        name: '',
        image: '',
        society_role: '',
        id: '',
        societyUserId: ''
    })

    const formSchema = Yup.object().shape({
    })

    const getSocietyUsers = async () => {
        try {
            let allUsers = await API.graphql({
                query: custumGetAllSocietyData,
                variables: {
                    id: params.id
                }
            })
            setUsers(allUsers?.data?.getSociety?.users?.items)
        } catch (err) {
            console.log(err)
        }
    }

    const handleRemove = async (societyUser, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Resident?`,
            buttons: ['Cancel', 'Remove'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                API.graphql({
                    query: deleteSocietyUser,
                    variables: {
                        input: {
                            id: societyUser.id
                        }
                    }
                }).then(() => {
                    const temp = [...users]
                    temp.splice(index, 1)
                    setUsers(temp)
                    toast.success(`${societyUser.user?.name} Removed from ${societyUser.name} successfully`)
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }

    const handleEdit = (societyUser) => {
        if (societyUser?.role !== 'ADMIN') {
            toast.warning('Can not update the society users')
        } else {
            setFormData({
                name: societyUser.user.name,
                id: societyUser.user.id,
                society_role: societyUser.role,
                image: societyUser.user.photo,
                societyUserId: societyUser.id
            })
            showModal(true)
        }
    }


    const handleEditSocietyUser = async (form) => {
        try {
            let updatedUser = await API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: form.id,
                        email: form.email,
                        photo: form.image,
                        name: form.name
                    }
                }
            })
            let updatedSociety = await API.graphql({
                query: updateSocietyUser,
                variables: {
                    input: {
                        id: formData.societyUserId,
                        role: form.society_role
                    }
                }
            })
            setUsers((prevState) => {
                const index = prevState.findIndex((user) => user?.user?.id === form.id);
                prevState[index].role = updatedSociety?.data?.updateSocietyUser?.role;
                prevState[index].user = updatedUser.data.updateUser
                return [...prevState];
            });
            showModal(false)
        } catch (err) {
            console.log(err)
        }
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]

        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }

    useEffect(() => {
        getSocietyUsers()
    }, [])

    return (
        <section>
            <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                <div className='card-header'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <form>
                                <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                    <input className='form-control list-search' type='search' placeholder='Search' />
                                    <span className='input-group-text'>
                                        <i className='fe fe-search'></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <Spinner show={spinner}>
                        <table className='table table-hover table-nowrap card-table'>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Role</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='list fs-base'>
                                {users?.map((item, index) => (
                                    <tr key={index}>
                                        {
                                            item?.user?.name &&
                                            <>
                                                <td>
                                                    <div className='avatar avatar-xs align-middle me-2'>
                                                        <img className='avatar-img rounded-circle' src={item?.user?.photo} alt='...' />
                                                    </div>
                                                </td>
                                                <td>
                                                    {item?.user?.name}
                                                </td>
                                                <td>
                                                    {item?.user?.phone}
                                                </td>
                                                <td>{item?.role}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <a href='#' className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </a>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleRemove(item) }}>
                                                                Remove user
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Spinner>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Society Admin</h3>
                    <hr />
                    <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleEditSocietyUser} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => {
                            const societyRoles = values?.society_role ? userRole?.filter((x) => x?.value === values?.society_role)[0] : null;
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='name' title='Society Name'
                                        value={values?.name} error={errors.name} onChange={handleChange} />

                                    <FormControl type='select1' title='Select Role' name='society_role' value={societyRoles} options={userRole} error={errors.society_role} onChange={handleChange} />

                                    <div className='form-group mt-3'>
                                        {values?.image && <img src={values?.image} width={200} alt='' className='preview' />}
                                        <div>
                                            <label>Image</label>
                                            <input name='image' type='file' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className='form-control' />
                                            {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                        </div>
                                    </div>

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Users
