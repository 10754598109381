import FormControl from '../../../components/formControl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { API, Storage } from 'aws-amplify'
import { toast } from 'react-toastify';
import { createSociety } from '../../../graphql/mutations'
import { listStates } from '../../../graphql/queries'
import Spinner from '../../../components/spinner'
import { useEffect, useMemo, useState } from 'react'
import NumberFormat from 'react-number-format'
import countryList from 'react-select-country-list'

const AddSociety = () => {
    const navigate = useNavigate();
    const societyType = require('../../../data/societyType.json')
    const [states, setState] = useState();
    const [spinner, setSpinner] = useState(false)
    const contryList = useMemo(() => countryList().getData(), [])

    const formData = {
        name: '',
        address: '',
        type: '',
        city: '',
        state: '',
        image: '',
        zip: '',
        country: '',
    }

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Please enter society name.'),
        type: Yup.string().required('Required'),
        address: Yup.string().required('Required'),
        city: Yup.string().required('Required'),
        state: Yup.object().required('Required'),
        image: Yup.string().required('Required'),
        country: Yup.object().required('Required'),
        zip: Yup.number().required('Required'),
    })

    const getStateList = async () => {
        try {
            let states = await API.graphql({
                query: listStates,
            })
            for (let item of states?.data?.listStates?.items) {
                item.label = item.name
                item.value = item.name
                delete item.name
            }
            setState(states?.data?.listStates?.items)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getStateList()
    }, [])

    const handleCreate = async (form) => {
        setSpinner(true)
        const addressData = JSON.stringify({ street: form.address, state: form.state.label, country: form.country.label, zip: form.zip, city: form.city });
        try {
            await API.graphql({
                query: createSociety,
                variables: {
                    input: {
                        cityId: form.state.id,
                        name: form.name,
                        type: form.type,
                        address: addressData,
                        photo: form.image
                    }
                }
            })
            navigate('/society')
            toast.success('Created Successfully')
        }
        catch (err) {
            console.log(err)
            toast.error(err)
            setSpinner(false)
        }
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]

        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }

    const handleCnacleCreate = () => {
        navigate(-1)
    }


    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Create Society
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreate} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => {

                                const societyTypes = values.type ? societyType.filter((x) => x.value === values.type)[0] : null;

                                return (
                                    <form className="mb-4" onSubmit={handleSubmit}>
                                        <FormControl type="text" title="Name" name='name' placeholder={'Society Name'} value={values.name} error={errors.name} onChange={handleChange} required={true} />

                                        <FormControl type="select1" title="Type" name='type' placeholder={'Eg: Recidential'} options={societyType} value={societyTypes} error={errors.type} onChange={handleChange} required={true} />

                                        <FormControl type="select" title="Country" name='country' options={contryList} placeholder={'Country'} value={values.country} error={errors.state} onChange={handleChange} required={true} />

                                        <FormControl type="select" title="State" name='state' options={states} placeholder={'State'} value={values.state} error={errors.state} onChange={handleChange} required={true} />

                                        <FormControl type="text" title="City" name='city' placeholder={'City'} value={values.city} error={errors.city} onChange={handleChange} required={true} />

                                        <FormControl type="text" title="Address" name='address' placeholder={'Address'} value={values.address} error={errors.address} onChange={handleChange} required={true} />

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Zip Code <span className='text-danger'>*</span></label>
                                            <NumberFormat
                                                className='form-control'
                                                name='zip'
                                                value={values.zip}
                                                onChange={handleChange}
                                                placeholder='Enter your Zip'
                                                format={'######'}
                                            />
                                            {errors.zip && touched.zip && <div className='text-danger mt-2 ms-1 h5'>{errors.zip}</div>}
                                        </div>

                                        <div className='form-group mt-3'>
                                            {values?.image && <img src={values?.image} width={'500'} alt="" className='preview' />}
                                            <div>
                                                <label>Image</label>
                                                <input name="image" type="file" onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className="form-control" />
                                                {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                            </div>
                                        </div>
                                        <Spinner show={spinner}>
                                            <button className="btn w-100 btn-primary" type='submit'>Create Society</button>
                                        </Spinner>
                                        <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                            Cancel
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddSociety
