import { API } from "aws-amplify"

export const getListStaff = async (token) => {
  const getListStaff = await API.graphql({
    query: `query getListStaff{
             listUsers(limit: 10, nextToken: ${token ? `"${token}"` : null}) {
    items {
      id
      name
     phone
      photo
      email
     role
    }
    nextToken
  }
            }`,
  })
  return getListStaff
}