import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify';
const sidebarList = require('../data/sidebar.json')

const Sidebar = () => {
    const navigate = useNavigate();

    const handleSignOut = () => {
        Auth.signOut().then((res) => {
            localStorage.clear();
            navigate('/')
        })
    }


    return (

        <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-brand text-center">
                    <img src='/img/logo.png' alt='logo' className="navbar-brand-img mx-auto" />
                </div>
                <div className='navbar-user d-md-none'>
                    <div className='dropdown'>
                        <Link id='sidebarIcon' className='dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <div className='avatar avatar-sm avatar-onlines'>
                                <img src='/img/profile.png' className='avatar-img rounded-circle' alt='...' />
                            </div>
                        </Link>
                        <div className='dropdown-menu dropdown-menu-end' aria-labelledby='sidebarIcon'>
                            <Link className='dropdown-item'>Profile</Link>
                            <Link className='dropdown-item'>Settings</Link>
                            <hr className='dropdown-divider' />
                            <Link className='dropdown-item' onClick={() => handleSignOut()}>Logout</Link>
                        </div>
                    </div>
                </div>
                <div className='collapse navbar-collapse' id='sidebarCollapse'>
                    <ul className='navbar-nav'>
                        {sidebarList.map((item, index) => (
                            <li className='nav-item' key={index}>
                                <Link className='nav-link' to={item.link}>
                                    <i className={item.icon}></i><span className={`${!item.icon.includes('fe') && 'ms-3'} `}>{item.name}</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar
