import { Routes, Route, useNavigate } from 'react-router-dom';
import { React, useState, useEffect } from 'react'

import Login from './pages/auth/login';

import AdminLayout from './layouts/adminLayout'
import Dashboard from './pages/dashboard/dashboard';
import AddSociety from './pages/dashboard/society/createSociety';
import SocietyList from './pages/dashboard/society/society';
import StaffList from './pages/dashboard/staff/staffList';
import AddStaff from './pages/dashboard/staff/addStaff';
import SocietyAdminLayout from './pages/dashboard/handleSocietyUsers/societyAdminLayout';
import CreateUser from './pages/dashboard/handleSocietyUsers/createUser';
import CreateSocietyUser from './pages/dashboard/handleSocietyUsers/createSocietyUser';

import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

Amplify.configure(awsconfig);
Auth.configure(awsconfig)

function App() {
  const navigate = useNavigate()

  useEffect(() => {
    checkAuthState();
  }, []);


  async function checkAuthState() {
    try {
    } catch (e) {
      navigate('/', { replace: true });
    }
  }

  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<AdminLayout />} >
          <Route path='/dashboard' element={<Dashboard />} />

          <Route path='/society' element={<SocietyList />} />
          <Route path='/society/create' element={<AddSociety />} />

          <Route path='society/:id/users' element={<SocietyAdminLayout />} />

          <Route path='/staff' element={<StaffList />} />
          <Route path='/create-staff' element={<AddStaff />} />

          <Route path='/society/:id/user/create' element={<CreateUser />} />
          <Route path='/society/:id/add/society' element={<CreateSocietyUser />} />
        </Route>
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
