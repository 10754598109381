import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../layouts/header'
import { useNavigate } from 'react-router-dom'
import { API, Storage } from 'aws-amplify';
import { listSocieties } from '../../../graphql/queries';
import { deleteSociety, updateSociety } from '../../../graphql/mutations'
import Swal from 'sweetalert'
import Spinner from '../../../components/spinner';
import FormControl from '../../../components/formControl';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';

const SocietyList = () => {
    const navigate = useNavigate();
    const [societyList, setSocietyList] = useState()
    const [nextArr, setNextArr] = useState([])

    const [modal, showModal] = useState(false)
    const [spinner, showSpinner] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        id: '',
        societyId: '',
        address: '',
        image: ''
    })

    const formSchema = Yup.object().shape({
    })

    const goToAddSocietyPage = () => {
        navigate('/society/create')
    }

    useEffect(() => {
        getSocietyList(null, true)
    }, [])

    const getSocietyList = async (token, prev) => {
        showSpinner(true)
        try {
            await API.graphql({
                query: listSocieties,
                variables: {
                    limit: 20,
                    nextToken: token || null
                },
            }).then(res => {
                if (res?.data?.listSocieties?.nextToken !== null && prev) {
                    let newUsers = res?.data?.listSocieties?.nextToken
                    setNextArr((prev) => ([...prev, newUsers]))
                }
                setSocietyList(res?.data?.listSocieties?.items)
            }
            )
            showSpinner(false)
        }
        catch (err) {
            console.log(err)
        }
    }

    const getAddress = (val) => {
        const obj = JSON.parse(val)
        const street = obj?.street
        const country = obj?.country
        if (!street) {
            return <p className='text-muted'>No Address</p>
        } else {
            return `${street || ''} ${country ? country : ''}`
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            getSocietyList(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            getSocietyList(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            getSocietyList(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handleDeleteSociety = (id, index) => {
        Swal({
            title: `Delete`,
            text: `Are you sure you want to delete it?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            if (status) {
                API.graphql({
                    query: deleteSociety,
                    variables: {
                        input: {
                            id: id,
                        }
                    }
                }).then((res) => {
                    const temp = [...societyList]
                    temp.splice(index, 1)
                    setSocietyList(temp)
                    toast.success('Society deleted successfully')
                }).catch((err) => toast.error(err.message))
            }
        })
    }

    const handleEdit = async (society) => {
        setFormData({
            name: society.name,
            id: society.id,
            address: !isEmpty(JSON.parse(society.address)).street || JSON.parse(society.address).street !== null ? JSON.parse(society.address).street : '',
            image: society.photo
        })
        showModal(true)
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]
        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }

    const handleEditSociety = async (form) => {
        try {
            let updatedSociety = await API.graphql({
                query: updateSociety,
                variables: {
                    input: {
                        id: formData.id,
                        address: JSON.stringify({ street: form.address }),
                        photo: form.image,
                        name: form.name
                    }
                }
            })
            setSocietyList((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index] = updatedSociety.data.updateSociety;
                return [...prevState];
            });
            showModal(false)
            toast.success('Updated successfully')
        } catch (err) {
            console.log(err)
        }
    }

    const goToSociety = (society) => {
        navigate(`/society/${society.id}/users`)
    }

    return (
        <>
            <Header title={'Society List'} onclick={goToAddSocietyPage} buttonTitle={'Create'} />
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                        <div className='card-header'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <form>
                                        <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                            <input className='form-control list-search' type='search' placeholder='Search' />
                                            <span className='input-group-text'>
                                                <i className='fe fe-search'></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Spinner show={spinner}>
                            <div className='table-responsive'>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Image</th>
                                            <th className='text-center'>Name</th>
                                            <th className='text-center'>Address</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base'>
                                        {societyList?.map((item, key) => (
                                            <tr key={item.id} className='cursor-pointer'>
                                                {
                                                    item?.photo ?
                                                        <td className='text-center' onClick={() => goToSociety(item)}>
                                                            <div className='avatar avatar-xs align-middle me-2'>
                                                                <img className='avatar-img rounded-circle' src={item?.photo} alt='...' />
                                                            </div>
                                                        </td>
                                                        :
                                                        <td className='text-center' onClick={() => goToSociety(item)}>
                                                            <span><i className='fe fe-image'></i></span>
                                                        </td>
                                                }
                                                <td className='text-center' onClick={() => goToSociety(item)}>{item?.name}</td>
                                                <td className='text-center' onClick={() => goToSociety(item)}>{getAddress(item?.address)}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => handleDeleteSociety(item.id, key)}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Spinner>
                        <div className='card-footer d-flex justify-content-between'>
                            <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                <li className='page-item' >
                                    <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                        <i className='fe fe-arrow-left me-1'></i> Prev
                                    </Link>
                                </li>
                            </ul>
                            <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                <li className='page-item'>
                                    <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                        Next <i className='fe fe-arrow-right ms-1'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Society</h3>
                    <hr />
                    <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleEditSociety} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors, touched }) => {

                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='name' title='Society Name'
                                        value={values?.name} error={errors.name} onChange={handleChange} />

                                    <FormControl type='text' name='address' title='address'
                                        value={values?.address} error={errors.address} onChange={handleChange} />

                                    <div className='form-group mt-3'>
                                        {values?.image && <img src={values?.image} width={200} alt='' className='preview' />}
                                        <div>
                                            <label>Image</label>
                                            <input name='image' type='file' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className='form-control' />
                                            {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                        </div>
                                    </div>

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SocietyList


