import React, { useEffect, useState } from 'react'
import Header from '../../layouts/header'
import { Link } from 'react-router-dom'


const Dashboard = () => {

    const tableData = [
        {
            "id": "01",
            "name": "test-name-1",
            "phone": 8521354852,
            "society": "prestige",
            "desc": "lorem ipsum",
            "status": 'Resolved',
            "attachment": "assets/img/avatars/teams/team-logo-1.jpg"
        },
        {
            "id": "02",
            "name": "test-name-2",
            "phone": 8521354852,
            "society": "prestige",
            "desc": "lorem ipsum",
            "status": 'Unresolved',
            "attachment": "assets/img/avatars/teams/team-logo-1.jpg"
        },
        {
            "id": "03",
            "name": "test-name-3",
            "phone": 8521354852,
            "society": "prestige",
            "desc": "lorem ipsum",
            "status": 'Reopen Ticket',
            "attachment": "assets/img/avatars/teams/team-logo-1.jpg"
        },
        {
            "id": "04",
            "name": "test-name-4",
            "phone": 8521354852,
            "society": "prestige",
            "desc": "lorem ipsum",
            "status": 'Unresolved',
            "attachment": "assets/img/avatars/teams/team-logo-1.jpg"
        },
        {
            "id": "05",
            "name": "test-name-5",
            "phone": 8521354852,
            "society": "prestige",
            "desc": "lorem ipsum",
            "status": 'Resolved',
            "attachment": "assets/img/avatars/teams/team-logo-1.jpg"
        }
    ]




    return (
        <>
            <Header title={'Dashboard'} />
            <div className="row">
                <div className="col-12 col-lg-6 col-xl">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                                <div className="col">
                                    <h6 className="text-uppercase text-muted mb-2">Total number of societies</h6>
                                    <span className="h2 mb-0">24,500</span>
                                </div>
                                <div className="col-auto">
                                    <span className="h2 fe fe-dollar-sign text-muted mb-0"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                                <div className="col">
                                    <h6 className="text-uppercase text-muted mb-2">Total number of resident user</h6>
                                    <span className="h2 mb-0">763</span>
                                </div>
                                <div className="col-auto">
                                    <span className="h2 fe fe-briefcase text-muted mb-0"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-6 col-xl">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                                <div className="col">
                                    <h6 className="text-uppercase text-muted mb-2">
                                        Total number of new users per day
                                    </h6>
                                    <span className="h2 mb-0">
                                        35
                                    </span>
                                </div>
                                <div className="col-auto">
                                    <div className="">
                                        {/* <canvas className="chart-canvas" id="sparklineChart" width="75" height="35" ></canvas> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-12 col-lg-6 col-xl">
                    <div className="card">
                        <div className="card-body">
                            <div className="row align-items-center gx-0">
                                <div className="col">
                                    <h6 className="text-uppercase text-muted mb-2">
                                        Total revenue generated
                                    </h6>
                                    <span className="h2 mb-0">
                                        23,398
                                    </span>
                                </div>
                                <div className="col-auto">
                                    <span className="h2 fe fe-dollar-sign text-muted mb-0"></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* Graph Section */}
            <div className="row">
                <div className="col-12 col-xl-8">


                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-header-title">Conversions</h4>
                            <span className="text-muted me-3">Last year comparision:</span>

                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="cardToggle" data-toggle="chart" data-target="#conversionsChart" data-trigger="change" data-action="add" data-dataset="1" />
                                <label className="form-check-label" for="cardToggle"></label>
                            </div>

                        </div>
                        <div className="card-body">


                            <div className="chart">
                                {/* <canvas id="conversionsChart" className="chart-canvas barGraph"></canvas> */}
                                <div id="chart-tooltip" role="tooltip" className="popover bs-popover-top barGraph-popover"><div className="popover-arrow translate-middle-x"></div><div className="popover-content"><div><h3 className="popover-header text-center text-nowrap">Oct 12</h3></div><div><div className="popover-body d-flex align-items-center text-nowrap justify-content-center "><span className="popover-body-indicator barGraph-indicator"></span> 32%</div></div></div></div></div>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-4">


                    <div className="card">
                        <div className="card-header">


                            <h4 className="card-header-title">
                                Traffic Channels
                            </h4>


                            <ul className="nav nav-tabs nav-tabs-sm card-header-tabs" role="tablist">
                                <li className="nav-item" data-toggle="chart" data-target="#trafficChart" data-trigger="click" data-action="toggle" data-dataset="0" role="presentation">
                                    <a href="#ds" className="nav-link active" data-bs-toggle="tab" aria-selected="true" role="tab">
                                        All
                                    </a>
                                </li>
                                <li className="nav-item" data-toggle="chart" data-target="#trafficChart" data-trigger="click" data-action="toggle" data-dataset="1" role="presentation">
                                    <a href="#sd" className="nav-link" data-bs-toggle="tab" aria-selected="false" tabindex="-1" role="tab">
                                        Direct
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <div className="card-body">


                            <div className="">
                                {/* <canvas id="trafficChart" className="chart-canvas pieChart" data-toggle="legend" data-target="#trafficChartLegend" ></canvas> */}
                                <div id="chart-tooltip" role="tooltip" className="popover bs-popover-top pieChart-popover"><div className="popover-arrow translate-middle-x"></div><div className="popover-content"><div><h3 className="popover-header text-center text-nowrap">Referral</h3></div><div><div className="popover-body d-flex align-items-center text-nowrap justify-content-center"><span className="popover-body-indicator pieChart-indicator"></span>15%</div></div></div></div></div>


                            <div id="trafficChartLegend" className="chart-legend"><div>
                                <span className="chart-legend-item">
                                    <span className="chart-legend-indicator chIndicator-1" ></span>Direct</span><span className="chart-legend-item"><span className="chart-legend-indicator chIndicator-2" ></span>Organic</span><span className="chart-legend-item"><span className="chart-legend-indicator chIndicator-3" ></span>Referral</span></div></div>

                        </div>
                    </div>
                </div>
            </div>
            {/* Complaint Table */}
            <div className="tab-content">
                <div className="tab-pane fade active show" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">
                    <div className="card" id="companiesList">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <form>
                                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                            <input className="form-control list-search" type="search" placeholder="Search" />
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-sm table-hover table-nowrap card-table">
                                <thead>
                                    <tr>
                                        <th><a className="list-sort text-muted" data-sort="item-name" href="#name">Name</a></th>
                                        <th><a className="list-sort text-muted" data-sort="item-name" href="#name">Phone</a></th>
                                        <th><a className="list-sort text-muted" data-sort="item-name" href="#name">Society</a></th>
                                        <th><a className="list-sort text-muted desc" data-sort="item-industry" href="#ind">Desc</a></th>
                                        <th><a className="list-sort text-muted desc" data-sort="item-industry" href="#ind">Attachment</a></th>
                                        <th><a className="list-sort text-muted" data-sort="item-location" href="#loc">Action</a></th>
                                    </tr>
                                </thead>
                                <tbody className="list fs-base">
                                    {tableData.map((item, key) => (
                                        <tr key={item.id}>
                                            <td><span className="item-industry">{item.name}</span></td>
                                            <td><span className="item-industry">{item.phone}</span></td>
                                            <td><span className="item-industry">{item.society}</span></td>
                                            <td><span className="item-industry">{item.desc}</span></td>
                                            <td><span className="item-industry">{item.attachment}</span></td>
                                            <td className="project-status">
                                                {item.status === 'Resolved' && <span className={`badge bg-success-soft`}>{item.status}</span>}
                                                {item.status === 'Unresolved' && <span className={`badge bg-danger-soft`}>{item.status}</span>}
                                                {item.status === 'Reopen Ticket' && <span className={`badge bg-warning-soft`}>{item.status}</span>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer d-flex justify-content-between">
                            <ul className="list-pagination-prev pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <a className="page-link ps-0 pe-4 border-end" href="#prev">
                                        <i className="fe fe-arrow-left me-1"></i> Prev
                                    </a>
                                </li>
                            </ul>
                            <ul className="list-pagination pagination pagination-tabs card-pagination"><li className="active"><a className="page" href="#page" data-i="1" data-page="10">1</a></li><li><a className="page" href="#page" data-i="2" data-page="10">2</a></li><li><a className="page" href="#page" data-i="3" data-page="10">3</a></li></ul>

                            <ul className="list-pagination-next pagination pagination-tabs card-pagination">
                                <li className="page-item">
                                    <a className="page-link ps-4 pe-0 border-start" href="#next">
                                        Next <i className="fe fe-arrow-right ms-1"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard
