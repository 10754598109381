export const custumGetAllSocietyData = /* GraphQL */ `
  query GetSociety($id: ID!) {
    getSociety(id: $id) {
      id
      type
      name
      cityId
      address
      photo
      buildings {
        items {
        id
        name
        societyId
      }
      }
      units {
        items {
        id
        name
        size
        type
      }
      }
      vechicles {
        items {
        id
        name
        number
        type
        societyId
        unitId
      }
      }
      amenities {
        items {
        name
        buildingId
        id
        societyId
      }
      }
      residents {
        items {
        userId
        updatedAt
        unitId
        type
        societyId
        buildingId
        approved
        id
      }
      }
      users {
        items {
        id
        role
        societyId
        user {
          id
          name
          email
          photo
          phone
        }
      }
      }
      createdAt
      updatedAt
    }
  }
`;

export const ListUsersQuery = `
 query ListUsersByFilter($phone: String!)
  { 
    listUsers(filter: { phone: { eq: $phone } })
     {
       items {
      societies {
        items {
          societyId
          userId
        }
      }
      id
    }
  }
    }`;

export const customGetUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      email
      phone
      photo
      birthday
      gender
      role
     residents {
      items {
        id
      }
    }
      societies {
      items {
        id
      }
    }
    }
  }
`;